.footer {
	// Use the two rules below to give the float bottom (see base for body padding properties)
	position: absolute;
	bottom: 0;
	padding: 20px 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: $footer_height + px;

	&.default {
		background-color: $footer_color_default;
	}

	&.inverted {
		background-color: $footer_color_inverted;
	}

	.text-muted {
		color: $gray_medium;
	}
}