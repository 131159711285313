.btn {
	border-radius: $btn_radius;
	font-family: $font_family;
	font-weight: $font_light;
	line-height: $line_height;
	transition: all .2s ease;
	margin-bottom: 10px;
	box-shadow: $button_shadow;
	position: relative;
	transition: all .3s ease;

	&[disabled] {
		opacity: 1;
	}

	&-block + &-block {
		margin-top: 0px;
	}

	&.active.focus,
	&:focus,
	&.focus {
		outline: none !important;
	}

	&-group .btn,
	&-group > .btn:first-child,
	&-group .btn + .btn {
		margin: 15px 5px;
		float: none;
	}

	.icon {
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		&:focus {
			outline: 0;
		}
	}

	&-link {
		padding: 0;
	}

	&.default {
		@include button-variant($brand_primary, $white, $brand_primary);

		&:hover {
			color: $white;
			background-color: $brand_primary;
			border-color: $brand_primary;
		}

		&:active,
		&:focus {
			color: $brand_primary;
			background-color: $white;
			border-color: $brand_primary;
		}

		&:active,
		&:active:hover {
			color: $white;
			background-color: $brand_primary;
			border-color: $brand_primary;
			box-shadow: none;
		}
	}

	&.primary {
		@include button-variant($gray_dark, $brand_primary, $brand_primary);

		&:hover,
		&:active,
		&.active,
		&.active:hover,
		&.active:focus,
		&:active:hover,
		&:active:focus,
		&.active.focus {
			color: $brand_primary;
			background-color: $gray_dark;
			border-color: $brand_primary;
		}

		&:focus,
		&.focus {
			color: $gray_dark;
			background-color: $brand_primary;
			border-color: $brand_primary;
		}
	}

	&.small {
		font-size: $font_sm;
		padding: .75rem 2rem;
	}

	&.base {
		font-size: $font_base;
		padding: 1rem 2rem;

		@media screen and (min-width: $sm_viewport) {
		font-size: $font_base;
		padding: 1rem 3rem;
		}
	}

	&.medium {
		font-size: $font_base;
		padding: 1rem 2rem;

		@media screen and (min-width: $sm_viewport) {
		font-size: $font_md;
		padding: 1rem 3rem;
		}
	}

	&.round--large {
		height: 125px;
		width: 125px;
		font-size: $font_md;
		border-radius: 100% !important;
		margin: 0 5px 10px;
	}

	&.round--medium {
		height: 70px;
		width: 70px;
		font-size: $font_lg;
		border-radius: 100%;
		margin: 10px 10px 10px 10px;

		+ .round--medium {
			margin: 10px 10px 10px 30px;
		}
	}

	&.round--base {
		height: 75px;
		width: 75px;
		font-size: $font_md;
		border-radius: 100%;
		margin: 0 10px 10px 0;
	}

	&.round--small {
		height: 40px;
		width: 40px;
		font-size: $font_sm;
		border-radius: 100%;
		margin: 0 0 0 0;
		padding: 0;

		@media screen and (device-aspect-ratio: 40/71) {
			height: 35px;
			width: 35px;
		}

		@media screen and (min-width: $sm_viewport) {
		height: 70px;
		width: 70px;
		font-size: $font_base;
		border-radius: 100%;
		margin: 0 5px 5px 0;
		}
	}

	.range {
		position: absolute;
		right: 10px;
		font-size: $font_sm;
		font-weight: 300;
		top: 17px
	}
}

@media screen  and (min-width: $sm_viewport) {
	.btn {
		&.round--large {
			height: 154px;
			width: 154px;
			font-size: $font_lg;
			border-radius: 100%;
			margin: 0 30px 10px;
		}
	}
}