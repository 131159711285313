//$jumbotron_insider: url(http://insiderexclusive.f2sites.com/wp-content/uploads/sites/12/2016/11/header.jpg);
$jumbotron_insider: url(/wp-content/uploads/2016/11/header.jpg);

.jumbotron {
	min-height: 160px;
	position: relative;
	margin-bottom: 0;
	padding: 20px 0;

	@media screen and (min-width: $sm_viewport) {
		min-height: 360px;
		padding: 15vh 0;
	}

	h1 {
		@include responsive_text_size('xxlarge');
	}

	p {
		@include responsive_text_size('base');
	}

	&.main {
		background-image: $jumbotron_insider;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center top;
	}

	&.gray--light {
		background: $gray_light;
	}
}



//@media screen and (min-width: $sm_viewport) {}

//@media screen and (min-width: $xlg_viewport) {}

//@media screen and (min-width: $huge_viewport) {}

/**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
//@media screen and (device-aspect-ratio: 40/71) {}