
#main-carousel {
  margin: 20px auto;
}
.carousel-inner {

	img {
		width: 100%;
	}
}
#main-carousel .carousel-indicators {
    margin: 10px 0 0;
    overflow: auto;
    position: static;
    text-align: left;
    white-space: nowrap;
    width: 100%;
}
#main-carousel .carousel-indicators li {
    background-color: transparent;
    -webkit-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    height: auto;
    margin: 0 !important;
    width: auto;
}
#main-carousel .carousel-indicators li img {
    display: block;
}
#main-carousel .carousel-outer {
    position: relative;
}

.carousel-caption {
	bottom: -5px;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, .6);

	h1,
	h2,
	h3,
	h4 {
		margin: 0;
	}

	a {
		color: $brand_secondary;
	}
}

.carousel-control .fa-angle-left,
.carousel-control .fa-angle-right {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 100px;
    top: 40%;
    position: absolute;
}
.carousel-control .fa-angle-left {

}
.carousel-control .fa-angle-right {

}

.carousel-indicators {

	.thumb {
       // filter: grayscale(1);

        img {
            max-width: 125px;
        }

        &.active {
            //filter: grayscale(0);
        }
    }
}