.jumbotron .text, .text {

	&--block {
		display: block;
	}

	&--white {
		color: $white;
	}

	&--black {
		color: $black;
	}

	&--primary {
		color: $brand_primary;
	}

	&--secondary {
		color: $brand_secondary;
	}

	&--accent {
		color: $brand_accent;
	}

	&--green {
		color: $brand_green;
	}

	&--blue {
		color: $brand_blue;
	}

	&--yellow {
		color: $brand_yellow !important;
	}

	&--disabled {
		color: darken($gray_light, 15%);
	}

	&--huge {
		@include responsive_text_size('huge');
	}

	&--xxlarge {
		@include responsive_text_size('xxlarge');
	}

	&--xlarge {
		@include responsive_text_size('xlarge');
	}

	&--large {
		@include responsive_text_size('large');
	}

	&--medium {
		@include responsive_text_size('medium');
	}

	&--base {
		@include responsive_text_size('base');
	}

	&--small {
		@include responsive_text_size('small');
	}

	&--xsmall {
		@include responsive_text_size('xsmall');
	}

	&--semibold {
		font-weight: $font_semi_bold;
	}

	&--bold {
		font-weight: $font_bold;
	}

	&--heavy {
		font-weight: $font_extra_bold;
	}

	&--underline {
		text-decoration: underline;
	}

	&--consent {
		padding: 10px 65px 0 221px;
	}

	&__disclaimer {
		a, button {
			line-height: $line_height;
			font-size: inherit;
			font-weight: inherit;
			vertical-align: initial;
			color: inherit;
			text-decoration: underline;
			&:hover, &:active, &:focus {
				color: inherit;
			}
		}
	}

	&__indent--40 {
    text-indent: 40px;
    display: block;
	}

	&__shadow--xlarge {
		text-shadow: $text_shadow_xl;
	}

	&__shadow--large {
		text-shadow: $text_shadow_lg;
	}

	&__shadow--medium {
		text-shadow: $text_shadow_md;
	}

	&__shadow--small {
		text-shadow: $text_shadow_sm;
	}

	&__shadow--xsmall {
		text-shadow: $text_shadow_xs;
	}
}

@media screen and (max-width: $sm_viewport_max) {
	.jumbotron .text, .text {

		&__mobile--center {
			text-align: center;
		}
		&__mobile--justify {
			 text-align: justify;
		}
	}
}