//@media screen and (min-width: $sm_viewport) {}
.navbar {
	border-radius: 0;
	margin-bottom: 0;
	min-height: ($navbar_height_int - 30) + px;
	box-shadow: $header_shadow;
	border: 0;

	@media screen and (min-width: $sm_viewport) {
	min-height: $navbar_height;
	}

	&-nav > li > a {
		color: $brand_primary;
    padding-top: 30px;
		padding-bottom: 30px;

		&:hover {
			background-color: lighten($nav_color_dark, 5%);
			color: $brand_primary;
		}
	}

	.container {
		position: relative;
	}

	.navbar-text {
		margin-top: 9px;
		margin-bottom: 9px;
		color: $brand_primary;
		font-weight: $font_light;

		&.out {
			position: absolute;
			right: -999px;
			z-index: 9;
		}
	}

	&__button {
		padding: 0.2rem 1.6rem !important;
		border-radius: 30px !important;
		min-width: 50px;
		top: 24%;
		margin-bottom: 0 !important;
		transition: all .3s ease;

		&.out {
			right: -9999px !important;
		}

		&.right {
			position: absolute;
			right: 15px;

			@media screen and (min-width: $sm_viewport) {
				right: 0;
			}
		}

		i {
			font-size: 1.9rem;
		}
	}

	&__telephone {
		font-weight: $font_normal;
		font-size: $font_md;
		line-height: $font_md + .2;
		transition: all .3s ease;
	}

	&.default {
		background-color: $nav_color_default;

			.navbar-text {
				color: inherit;

				a {
					color: $brand_primary;
				}
			}
	} // End of .navbar.defaulr code block

	&.inverted {
		background-color: $nav_color_dark;

			.navbar-text {
				color: $white;

				a {
					color: $brand_primary;
				}
			}
	}

	&__inset {
		box-shadow: $box_shadow_inset;
	}

	&__header {
		@extend .navbar-header;
		height: ($navbar_height_int - 30) + px;
		transition: all .8s ease;

		@media screen and (min-width: $sm_viewport) {
			height: $navbar_height;
		}

		&.centered {
			width: 100%;
			text-align: center;
		}
	} // End of .navbar__header code block

	&__brand {
		@extend .navbar-brand;
		font-size: $font_base;
		display: inline-block;
		padding: 0;
		float: none;
		width: $logo_mobile_width;
		height: ($navbar_height_int - 30) + px;
		transition: all 1s ease;
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center;

		@media screen and (min-width: $sm_viewport) {
			width: $logo_width;
			height: $navbar_height;
			margin-left: 0 !important;
		}

		&.out {
			margin-left: -9999px !important;
		}

		&.in {
			margin-left: -15px;
		}
	}

	&.default &__brand{
		background-image: url($logo_default_svg);
	}

	&.inverted &__brand{
		background-image: url($logo_white_svg);
	}
}

.nosvg {
	.navbar {

		&__brand {

			&.default {
				background-image: url($images_path + $logo_default_png);
			}
		}
	}
}