@media screen and (min-width: $sm_viewport) {
	.jumbotron .margin,
	.margin {

		&--0  {margin: 0}
		&--10 {margin: 10px}
		&--20 {margin: 20px}
		&--30 {margin: 30px}
		&--40 {margin: 40px}
		&--50 {margin: 50px}

		&__top {
			&--0  {margin-top: 0;}
			&--10 {margin-top: 10px;}
			&--20 {margin-top: 20px;}
			&--30 {margin-top: 30px;}
			&--40 {margin-top: 40px;}
			&--50 {margin-top: 50px;}
		}

		&__right {
			&--0  {margin-right: 0;}
			&--10 {margin-right: 10px;}
			&--20 {margin-right: 20px;}
			&--30 {margin-right: 30px;}
			&--40 {margin-right: 40px;}
			&--50 {margin-right: 50px;}
		}

		&__bottom {
			&--0  {margin-bottom: 0;}
			&--10 {margin-bottom: 10px;}
			&--20 {margin-bottom: 20px;}
			&--30 {margin-bottom: 30px;}
			&--40 {margin-bottom: 40px;}
			&--50 {margin-bottom: 50px;}
		}

		&__left {
			&--0  {margin-left: 0;}
			&--10 {margin-left: 10px;}
			&--20 {margin-left: 20px;}
			&--30 {margin-left: 30px;}
			&--40 {margin-left: 40px;}
			&--50 {margin-left: 50px;}
		}

	}
}


@media screen and (max-width: $sm_viewport_max) {
	.jumbotron .margin,
	.margin {

		&--0  {margin: 0}
		&--10 {margin: 10px}
		&--20 {margin: 20px}
		&--30 {margin: 30px}
		&--40 {margin: 40px}
		&--50 {margin: 50px}

		&__topxs {
			&--0  {margin-top: 0;}
			&--10 {margin-top: 10px;}
			&--20 {margin-top: 20px;}
			&--30 {margin-top: 30px;}
			&--40 {margin-top: 40px;}
			&--50 {margin-top: 50px;}
		}

		&__rightxs {
			&--0  {margin-right: 0;}
			&--10 {margin-right: 10px;}
			&--20 {margin-right: 20px;}
			&--30 {margin-right: 30px;}
			&--40 {margin-right: 40px;}
			&--50 {margin-right: 50px;}
		}

		&__bottomxs {
			&--0  {margin-bottom: 0;}
			&--10 {margin-bottom: 10px;}
			&--20 {margin-bottom: 20px;}
			&--30 {margin-bottom: 30px;}
			&--40 {margin-bottom: 40px;}
			&--50 {margin-bottom: 50px;}
		}

		&__leftxs {
			&--0  {margin-left: 0;}
			&--10 {margin-left: 10px;}
			&--20 {margin-left: 20px;}
			&--30 {margin-left: 30px;}
			&--40 {margin-left: 40px;}
			&--50 {margin-left: 50px;}
		}

	}
}

@media screen and (min-width: $sm_viewport) {
	.jumbotron .padding,
	.padding {

		&--0  {padding: 0}
		&--10 {padding: 10px}
		&--20 {padding: 20px}
		&--30 {padding: 30px}
		&--40 {padding: 40px}
		&--50 {padding: 50px}

		&__top {
			&--0  {padding-top: 0;}
			&--10 {padding-top: 10px;}
			&--20 {padding-top: 20px;}
			&--30 {padding-top: 30px;}
			&--40 {padding-top: 40px;}
			&--50 {padding-top: 50px;}
		}

		&__right {
			&--0  {padding-right: 0;}
			&--10 {padding-right: 10px;}
			&--20 {padding-right: 20px;}
			&--30 {padding-right: 30px;}
			&--40 {padding-right: 40px;}
			&--50 {padding-right: 50px;}
		}

		&__bottom {
			&--0  {padding-bottom: 0;}
			&--10 {padding-bottom: 10px;}
			&--20 {padding-bottom: 20px;}
			&--30 {padding-bottom: 30px;}
			&--40 {padding-bottom: 40px;}
			&--50 {padding-bottom: 50px;}
		}

		&__left {
			&--0  {padding-left: 0;}
			&--10 {padding-left: 10px;}
			&--20 {padding-left: 20px;}
			&--30 {padding-left: 30px;}
			&--40 {padding-left: 40px;}
			&--50 {padding-left: 50px;}
		}

	}
}


@media screen and (max-width: $sm_viewport_max) {
	.jumbotron .padding,
	.padding {

		&--0  {padding: 0}
		&--10 {padding: 10px}
		&--20 {padding: 20px}
		&--30 {padding: 30px}
		&--40 {padding: 40px}
		&--50 {padding: 50px}

		&__topxs {
			&--0  {padding-top: 0;}
			&--10 {padding-top: 10px;}
			&--20 {padding-top: 20px;}
			&--30 {padding-top: 30px;}
			&--40 {padding-top: 40px;}
			&--50 {padding-top: 50px;}
		}

		&__rightxs {
			&--0  {padding-right: 0;}
			&--10 {padding-right: 10px;}
			&--20 {padding-right: 20px;}
			&--30 {padding-right: 30px;}
			&--40 {padding-right: 40px;}
			&--50 {padding-right: 50px;}
		}

		&__bottomxs {
			&--0  {padding-bottom: 0;}
			&--10 {padding-bottom: 10px;}
			&--20 {padding-bottom: 20px;}
			&--30 {padding-bottom: 30px;}
			&--40 {padding-bottom: 40px;}
			&--50 {padding-bottom: 50px;}
		}

		&__leftxs {
			&--0  {padding-left: 0;}
			&--10 {padding-left: 10px;}
			&--20 {padding-left: 20px;}
			&--30 {padding-left: 30px;}
			&--40 {padding-left: 40px;}
			&--50 {padding-left: 50px;}
		}

	}
}