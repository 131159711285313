@import '../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '../../bower_components/font-awesome/scss/font-awesome.scss';

@import '_default.scss';
@import '_font_open-sans.scss';
@import '_variables.scss';
@import 'lib/functions/_responsive.scss';
@import 'lib/functions/_typography.scss';
@import 'lib/mixins/_responsive.scss';
@import 'lib/mixins/_typography.scss';
@import 'lib/mixins/_color.scss';
@import '_base.scss';
@import 'layouts/_navbar.scss';
@import 'modules/_jumbotrons.scss';
@import 'modules/_typography.scss';
@import 'modules/_buttons.scss';
@import 'modules/_footer.scss';
@import 'modules/_inputs.scss';
@import 'modules/_margins.scss';
@import 'modules/_sidebar.scss';
@import 'modules/_lists.scss';
@import 'modules/_banners.scss';
@import 'modules/_carousels.scss';
@import 'modules/_sliders.scss';


.container {
  @media screen and (min-width: $sm_viewport) {
    width: 100%;
  }
  @media screen and (min-width: $lg_viewport) {
    width: 1170px;
  }
}

.embed-responsive-item {
  border: 0;
}

.input-group-btn .btn {
  height: 45px;
  box-shadow: none;
}

.show__container--small {
  min-height: 175px;

  .title {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
  }
}

.nav.home {
  margin-top: -20px;
}

.nav-tabs {
  border-bottom: 0;
}

.nav-tabs>li.active>a {
  background-color: transparent;
  border: 0;
  color: $brand_primary;
}

.nav-tabs>li>a,
.nav-tabs>li>a:hover,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: $brand_primary;
  background-color: transparent;
  border: 0;
}
