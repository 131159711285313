.banner {

	&--affiliates {
		margin-bottom: 5px;

		img {
			width: 100%;
		}
	}
}
