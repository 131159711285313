html {
	min-height: 100%;
	font-size: 62.5%;
}

body {
	background-color: $site_bg_color;
	font-family: $open_sans;
	font-size: $font_base;
	color: $white;
	line-height: 2.6rem;
	/* Margin bottom by footer height */
	position: relative;
	padding-top: ($navbar_height_int - 30) + px;
	padding-bottom: ($footer_height * 2) + px;

	@media screen and (min-width: $sm_viewport) {
		padding-top: $navbar_height;
	}
}

a {
	color: $brand_primary;

	&:hover {
		color: darken($brand_primary, 10%);
	}
}

p {
	margin-bottom: 20px;
}


// h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {}
h1,
h2,
h3,
h4 {
	font-weight: 300;
	color: $brand_primary;
}

h1 {
	@include responsive_text_size('xlarge');

	small {
		color: inherit;
	}
}

h2 {
	@include responsive_text_size('large');
}

h3 {
	@include responsive_text_size('medium');
}

h4 {
	@include responsive_text_size('base');
}

h5,
h6 {
	@include responsive_text_size('base');
}

small {
	@include responsive_text_size('xsmall');
}

::-webkit-scrollbar              { /* 1 */ }
::-webkit-scrollbar-button       { /* 2 */ }
::-webkit-scrollbar-track        { /* 3 */ }
::-webkit-scrollbar-track-piece  { /* 4 */ }
::-webkit-scrollbar-thumb        { /* 5 */ }
::-webkit-scrollbar-corner       { /* 6 */ }
::-webkit-resizer                { /* 7 */ }

/* Let's get this party started */
::-webkit-scrollbar {
		width: 5px;

    &:horizontal {
    	height: 5px;
    }
}



/* Track */
::-webkit-scrollbar-track {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: $rgb_white_10;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #ffdf08;
}

::-webkit-scrollbar-thumb:window-inactive {
	background: #ffdf08;
}