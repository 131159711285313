select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="button"],
input[type="color"],
.btn__input {
	@extend .form-control;
	border-radius: $input_radius;
	box-shadow: inset 0 1px 5px 0px $rgb_black_20;
	font-family: $font_family;
	font-weight: $font_semi_bold;
	height: $input_height;
	border: 0;
	color: $font_color;
	font-size: $font_sm - .2;

	&::-webkit-input-placeholder {
		color: $font_color;
		font-size: $font_sm - .2;
	}
}

[placeholder]:focus::-webkit-input-placeholder,
.form-control:focus::-webkit-input-placeholder,
input[type="text"]:focus::-webkit-input-placeholder {
	transition: opacity .2s ease;
	opacity: 0;
}

input[readonly][type="text"] {
	background-color: $white;
}

select {
	appearance: none;
	background-image: url($images_path + 'icons/chevron-down.svg');
	background-size: 20px;
	background-position: right 10px center;
	background-repeat: no-repeat;
	color: $brand_primary;
}

label {
	font-weight: $font_light;
	font-size: $font_base;
}

input[type="button"] {
	text-align: left;
}

.input-group {

	input[type="button"]:first-child {
		text-align: left;
	}
}

.form-group:last-child {
	margin-bottom: 0;
}

.checkbox label {
	font-size: $font_sm;
}

input[type="tel"] {

	&.zipcode {
		min-height: 54px;
		font-size: $font_base + .2;
		font-weight: $font_light;
		color: $font_color;
		text-align: center;
	}

	&::-webkit-input-placeholder {
		 color: $font_color;
	}
}


select {
	appearance: none;
	//background: $white url("../images/icons/select-arrow.png") no-repeat right 10px center;
}

@media screen and (min-width: $md_viewport) {
	input[type="text"].phone {
		text-align: center;
	}
}


.input-group-addon {
	padding: 6px 12px;
	font-size: $font_base;
	font-weight: normal;
	line-height: $line_height;
	color: $brand_primary;
	text-align: center;
	background-color: $brand_secondary;
	border: 1px solid darken($brand_secondary, 10%);
	border-radius: $input_radius;
}

.switch-field {
	overflow: hidden;
	background-color: $gray_dark;
	display: inline-block;
	border-radius: 10px;
	margin: 10px 0 10px 0;
}

.switch-title {
	margin-bottom: 6px;
}

.switch-field input {
	display: none;
}

.switch-field label {
	float: left;
}

.switch-field label {
	display: inline-block;
	width: 80px;
	height: 55px;
	background-color: transparent;
	color: $white;
	font-size: $font_base;
	line-height: 40px;
	font-weight: normal;
	text-align: center;
	text-shadow: none;
	vertical-align: middle;
	padding: 6px 14px;
	margin-bottom: 0;
	transition: all 0.1s ease-in-out;
}

.switch-field label:nth-child(2) {
	width: 60px;
	border-radius: 100px;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field label.isChecked {
	background-color: $brand_secondary;
	-webkit-box-shadow: none;
	box-shadow: none;
	color: #282128;
}

.switch-field label:checked:nth-child(2) {
	border-radius: 100%;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

@media screen and (min-width: 768px) {
	.switch-field {
		margin: 30px 0 30px 0;
	}
}


@media screen and (max-width: 767px) {
	.switch-field label {
		height: 45px;
		line-height: 30px;
	}
	.switch-field label:nth-child(2) {
			width: 45px;
	}
	.control-label {
		width: 100%;
	}
}

.nosvg {
	select {
		background-image: url($images_path + 'icons/chevron-down.png');
	}
}