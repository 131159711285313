.sidebar {

	&__info {
		margin-bottom: 30px;

		h4 {
			font-size: $font_base;
		}

		p {
			font-size: $font_base - .4;
			margin-bottom: 10px;
			line-height: $font_base - .4;
		}

		address {
			font-size: $font_base - .4;
			margin-bottom: 10px;
		}

		.fa {
			margin-right: 10px;
		}
	}

	&__more {

		img {
			margin-bottom: 10px;
			width: 100%;
		}

		.title {
			font-size: $font_base - .2;
		}
	}
}