.slider {
	overflow-x: scroll;

	&__container {
		@extend .clearfix;
		width: 100%;
	}

	&__item {
		float: left;
		margin: 5px 5px 5px 0;

		&:last-child {
			margin: 5px 0 5px 0;
		}

		a {
			display: inline-block;
		}
	}


	&.networks &__item{
		width: 200px;
		height: 105px;
		text-align: center;

		.fa {
			padding: 20px 0;
		}
	}

	&.top-shows &__item{
		text-align: center;

		img {
			width: 326px;
			height: 200px;
		}

		.fa {
			padding: 20px 0;
		}
	}

	&.sponsors &__item{
		text-align: center;

		img {
			width: 200px;
			height: 115px;
		}

		.fa {
			padding: 20px 0;
		}
	}

	&.top-shows	.slider__container {
		width: 12000px;
	}

	&.sponsors .slider__container {
		width: 6000px;
	}

	&.books &__item{
		width: 124px;
		height: 200px;
		text-align: center;

		img {
			width: 124px;
			height: 200px;
		}

		.fa {
			padding: 50px 0;
		}
	}
}