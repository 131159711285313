// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$images_path	: '';
$logo_default_svg	: '../images/logo-default.svg';
// $logo_default_svg	: '/wp-content/uploads/2016/11/logo-default.svg';
$logo_default_png	: 'global/logo-default.png';
$logo_white_svg		: '../images/logo-default.svg';
$logo_white_png		: 'global/logo-white.png';
$logo_internet_svg: 'global/logo-internet.svg';
$logo_internet_png: 'global/logo-internet.png';


$logo_width: 200px;
$logo_mobile_width: 100px;

// Fonts and Icons
$open_sans		: 'Open Sans';
$font_awesome	: 'FontAwesome';
$icon_set			: $font_awesome;
$font_family	: $open_sans;
$font_color		: #5d5d5d;

$navbar_height_int		: 80;
$footer_height_int		: 100;
$footer_height_auto		: auto;
$navbar_height				: $navbar_height_int + px;
$footer_height				: $footer_height_int;
$header_footer_height	: $navbar_height_int + $footer_height_int;

// Font Sizes
$font_xs	: 1.1rem;
$font_sm	: 1.4rem;
$font_base: 1.8rem;
$font_md	: 2.2rem;
$font_lg	: 3.8rem;
$font_xl	: 4rem;
$font_xxl	: 5.2rem;
$font_huge: 8rem;

$line_height : 1.42857143;

// Viewports
$legacy_viewport_max : 320px;
$xs_viewport_max : 480px - 1;
$sm_viewport_max : 768px - 1;
$md_viewport_max : 992px - 1;
$lg_viewport_max : 1200px - 1;

$vp_legacy		: 400px;
$xs_viewport	: 480px;
$sm_viewport	: 768px;
$md_viewport	: 992px;
$lg_viewport	: 1200px;
$xlg_viewport	: 1680px;
$huge_viewport: 1920px;

$breakpoints: (
	'legacy':	$vp_legacy,
	'xs':			$xs_viewport,
	'sm':			$sm_viewport,
	'md':			$md_viewport,
	'lg':			$lg_viewport
);

$text-sizing: (
	'xsmall': (
		'xs': (
			'font-size': $font_xs,
			'line-height': $font_xs * 1.6,
			'margin': 0 0 5px
		)
	),
	'small': (
		'xs': (
			'font-size': $font_sm,
			'line-height': $font_sm * 1.6,
			'margin': 0 0 5px
		)
	),
	'base': (
		'xs': (
			'font-size': $font_base,
			'line-height': $font_base * 1.2,
			'margin': 0 0 10px
		),
		'sm': (
			'line-height': $font_base * 1.6,
			'margin': 0 0 10px
		)
	),
	'medium': (
		'xs': (
			'font-size': $font_md / 1.45,
			'line-height': $font_md / 1.2,
			'margin': 0 0 20px
		),
		'sm': (
			'font-size': $font_md,
			'line-height': $font_md,
			'margin': 0 0 10px
		)
	),
	'large': (
		'xs': (
			'font-size': $font_lg / 1.5,
			'line-height': $font_lg / 1.5,
			'margin': 0 0 10px
		),
		'sm': (
			'font-size': $font_lg,
			'line-height': $font_lg,
			'margin': 0 0 20px
		)
	),
	'xlarge': (
		'xs': (
			'font-size': $font_xl / 1.5,
			'line-height': $font_xl / 1.25,
			'margin': 0 0 10px
		),
		'sm': (
			'font-size': $font_xl,
			'line-height': $font_xl,
			'margin': 0 0 20px
		)
	),
	'xxlarge': (
		'xs': (
			'font-size': $font_xxl / 2,
			'line-height': $font_xxl / 2,
			'margin': 0 0 10px
		),
		'sm': (
			'font-size': $font_xxl,
			'line-height': $font_xxl,
			'margin': 0 0 20px
		)
	),
	'huge': (
		'xs': (
			'font-size': $font_huge / 2,
			'line-height': $font_huge / 2
		),
		'sm': (
			'font-size': $font_huge,
			'line-height': $font_huge * 1.6
		)
	)
);

// Font Weights
$font_light			: 300;
$font_normal		: 400;
$font_semi_bold	: 600;
$font_bold			: 700;
$font_extra_bold: 800;

$rgb_black_10	: rgba(0, 0, 0, .1);
$rgb_black_20	: rgba(0, 0, 0, .2);
$rgb_black_30	: rgba(0, 0, 0, .3);
$rgb_black_40	: rgba(0, 0, 0, .4);
$rgb_black_50	: rgba(0, 0, 0, .5);
$rgb_black_60	: rgba(0, 0, 0, .6);
$rgb_black_70	: rgba(0, 0, 0, .7);
$rgb_black_80	: rgba(0, 0, 0, .8);
$rgb_black_90	: rgba(0, 0, 0, .9);

$rgb_white_10	: rgba(255, 255, 255, .1);
$rgb_white_20	: rgba(255, 255, 255, .2);
$rgb_white_30	: rgba(255, 255, 255, .3);
$rgb_white_40	: rgba(255, 255, 255, .4);
$rgb_white_50	: rgba(255, 255, 255, .5);
$rgb_white_60	: rgba(255, 255, 255, .6);
$rgb_white_70	: rgba(255, 255, 255, .7);
$rgb_white_80	: rgba(255, 255, 255, .8);
$rgb_white_90	: rgba(255, 255, 255, .9);

$text_shadow_xs		: 0 1px 1px $rgb_black_40;
$text_shadow_sm		: 0 1px 3px $rgb_black_60;
$text_shadow_md		: 0 2px 2px $rgb_black_40;
$text_shadow_lg		: 0 3px 2px $rgb_black_40;
$text_shadow_xl		: 0 4px 5px $rgb_black_30;
$header_shadow		: 0 2px 5px $rgb_black_20;
$button_shadow		: 0 2px 3px $rgb_black_30;
$box_shadow_inset	: inset 0 0 20px 1px $rgb_black_10;
$box_shadow_lg		: 0 2px 5px $rgb_black_20;

// Colors
//$brand_primary				: #ffdf08;
$brand_primary				: #3C9BE6;
$brand_secondary			: #f8c52f;
$brand_accent					: #ff8827;
$brand_green					: #32cd32;
$brand_yellow					: #ffd200;

$brand_orange					: #ff7e00;
$brand_teal						: #47c184;
$brand_ghostwhite			: #f8f8ff;
$brand_blue						: #2192df;
$brand_red						: #EF5350;
$brand_blue_secondary	: #2183ca;
$at120	: #d10e5a;
$at200	: #f93817;

// Shades
$gray        : #a8a8a8;
$gray_dark   : #111111;
$gray_light  : #f1f1f1;
$gray_medium : #727272;
$white       : #fff;
$black       : #000;

$site_bg_color: $gray_dark;

// Radius
$input_radius : 4px;
$btn_radius   : 4px;
$well_radius  : 4px;

$input_height : 45px;

$nav_color_dark: $site_bg_color;
$nav_color_white: $white;
$nav_color_default: $nav_color_white;
$nav_color_inverted: $nav_color_dark;

$footer_color_dark: #242424;
$footer_color_white: $white;
$footer_color_default: $footer_color_white;
$footer_color_inverted: $footer_color_dark;