.list {

	&__guestlist {
		font-size: $font_sm;

		.fa {
			margin-right: 5px;
		}

		a {
			color: $white;
		}
	}
}