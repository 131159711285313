
/*
 * WARNING! Commercial Software, All Use Must Be Licensed
 *
 * Revenue Media Empire, Inc. DBA Walla Media.  All Rights Reserved.
 * @copyright 2015,  Revenue Media Empire
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Revenue Media Empire and its suppliers, if any. The intellectual and technical
 * concepts contained herein are proprietary to Revenue Media Empire and its
 * suppliers and may be covered by U.S. and Foreign Patents, patents in process,
 * and are protected by trade secret or copyright law. Unauthorized use,
 * duplication, dissemination, reproduction, reverse engineering, any form of
 * redistribution, or use in part or in whole other than by prior, express,
 * printed and signed license for use is subject to civil and criminal
 * prosecution. If you have rmetriceceived this file in error, please notify
 * Revenue Media Empire and destroy this and any other copies as instructed.
 */

// Node Presentation Styles
.wportal-layout .wportal-node + .wportal-node,
.wportal-layout + .wportal-layout,
.wportal-lightbox {
	display: none;
}

// Default Destination Folder not sites
$dest_path: '/';
// Fonts are global
$fonts_path: $dest_path + 'fonts/';
$fa-font-path:	$dest_path + 'fonts/';
